import React, { useEffect, useRef } from "react";
import { Image } from "../../../components";
import { NavLink } from "react-router-dom";
import CouplePNG from "../../../img/couple.png";
import GreenCircleJPG from "../../../img/bg-circles.png";
import GreenCircleWEBP from "../../../img/bg-circles.webp";
import useSize from "../../../hooks/useSize";
import { greenBackground } from "../../../utilities/greenBackground";

export default function SectionConsiderations() {
  const imageRef = useRef();
  const backgroundRef = useRef();
  const size = useSize(imageRef);

  useEffect(() => {
    if (typeof size.width !== "undefined")
      greenBackground(backgroundRef, size, "right");
  }, [size]);

  return (
    <section className="section section--grey section--half-half section--considerations">
      <div className="inner-container">
        <div className="left">
          <h2>
            Considerations for your patients when prescribing Bunov
            <sup>&reg;</sup>
          </h2>
          <p>
            Bunov<sup>&reg;</sup> is a chronic opioid therapy; therefore,
            careful consideration should be given to the previous opioid history
            of the patient to avoid symptoms associated with abstinence syndrome
            or opioid withdrawal during conversion from entry opioid to Bunov
            <sup>&reg;</sup>.<sup>3</sup>
          </p>
          <p>
            Due to the risk of addiction, abuse and misuse associated with
            opioids, it is critical to ensure consistent assessment of the
            patient to ensure dosing and duration of treatment is optimal.
            <sup>3</sup>
          </p>
          <ul>
            <li>
              The patient should be carefully and regularly monitored to assess
              the optimum dose and duration of treatment<sup>3</sup>
            </li>
            <li>
              Even when dosed correctly, Bunov<sup>&reg;</sup> has a low risk of
              physical dependence<sup>3</sup> and thus increases and decreases
              in dosing must be closely managed
            </li>
          </ul>
          <p>
            Bunov<sup>&reg;</sup> opioid patches should also be used cautiously in patients
            taking certain antidepressants, sedative H1-receptor agonists,
            alcohol, anxiolytics, neuroleptics, clonidine and related
            substances. These, alongside opioid derivatives, can increase the
            CNS depressant activity.<sup>3</sup>
          </p>

          <NavLink
            to="/hcp/resources"
            data-path="/hcp/resources"
            className="btn btn--primary home-explore"
          >
            Explore resources
          </NavLink>
        </div>
        <div className="right align-end">
          <div className="green-background" ref={backgroundRef}>
            <img
              src={GreenCircleJPG}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
              }}
            />
          </div>
          <img
            src={CouplePNG}
            alt="An elderly couple are happy and enjoying a walk on the beach."
            className="couple-image"
            ref={imageRef}
          />
        </div>
      </div>
    </section>
  );
}
