import React, { useEffect } from "react";
import { References, HomeHero, ExternalLink } from "../../../components";
import SectionPrescribe from "./section-prescribe";
import SectionAvailableDoses from "./section-available-doses";
import SectionContraindications from "./section-contraindications";
import SectionGraph from "./section-graph";
import SectionConsiderations from "./section-considerations";

export default function Home() {
  useEffect(() => {
    document.title = "Bunov® - HCP";
  }, []);

  return (
    <main>
      <HomeHero />
      <SectionPrescribe />
      <SectionAvailableDoses />
      <SectionGraph />
      <SectionContraindications />
      <SectionConsiderations />

      <section className="section section--references section--abbreviations section--white">
        <div className="inner-container">
          <h6>Abbreviations</h6>
          <p>CNS, central nervous system; MAO, monoamine oxidase.</p>
        </div>
      </section>
      <References>
        <ol>

          <li>
            Data on file – Ratio of Bunov price to generic/tariff pricing
            (Glenmark Pharmaceuticals Ltd). BUN/2022/04/001.
          </li>
          <li>
            Public Assessment Report. Bunov transdermal patches, Glenmark
            Pharmaceuticals Ltd.
          </li>
          <li>
            Bunov Summary of Product Characteristics (SmPC). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
              title="https://www.medicines.org.uk/emc/search?q=%22Bunov%22"
            />
          </li>
          <li>
            Bunov Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.9797.pdf"
            />
          </li>
        </ol>
        <p>
          <sup>&reg;</sup> BUTRANS is a registered trade mark of Napp
          Pharmaceuticals Ltd.
        </p>
      </References>
    </main>
  );
}
