import React, { useEffect, useRef } from "react";
import { Image } from "../../";
import PatchSVG from "../../../img/patch-icon.svg";
import CoinSVG from "../../../img/icon-money.svg";
import HandSVG from "../../../img/icon-hand.svg";
import Hero1JPG from "../../../img/hero-1.jpg";
import Hero1WEBP from "../../../img/hero-1.webp";
import Hero2JPG from "../../../img/hero-2.jpg";
import Hero2WEBP from "../../../img/hero-2.webp";
import Hero3JPG from "../../../img/hero-3.jpg";
import Hero3WEBP from "../../../img/hero-3.webp";
import CirclePNG from "../../../img/hero-circles.png";
import CircleWEBP from "../../../img/hero-circles.webp";

export default function HomeHero() {
  const heroRef = useRef();

  useEffect(() => {
    positionHeroImages();

    window.addEventListener("resize", () => {
      positionHeroImages();
    });
  }, []);

  const positionHeroImages = () => {
    const heroElm = heroRef.current;
    const windowWidth = window.innerWidth;

    if (heroElm) {
      const images = document.querySelectorAll(".image-wrapper img");
      const heroHeight = heroElm.offsetHeight;
      const rightElm = heroElm.querySelector(".right");
      const rightPosition = rightElm.getBoundingClientRect();

      if (windowWidth <= 600) {
        images.forEach((img) => {
          img.style.height = `314px`;
          img.style.width = `${(windowWidth - rightPosition.x) / 3}px`;
        });
      } else {
        images.forEach((img) => {
          img.style.width = `${(windowWidth - rightPosition.x) / 3}px`;
          img.style.height = `${heroHeight + 35}px`;
        });
      }
    }
  };

  return (
    <div className="hero" ref={heroRef}>
      <div className="inner-container">
        <Image
          webp={CircleWEBP}
          src={CirclePNG}
          className="hero-home-circles"
        />

        <svg
          width="232"
          height="249"
          viewBox="0 0 232 249"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="hide-desktop mobile-circles"
        >
          <path
            opacity="0.2"
            d="M231.903 248C189.695 248 155.479 213.784 155.479 171.576C155.479 129.367 189.695 95.1511 231.903 95.1511"
            stroke="#FCFCFC"
          />
          <path
            opacity="0.2"
            d="M1.11133 0C1.11133 79.9438 65.9186 144.751 145.862 144.751C178.199 144.751 208.06 134.147 232.156 116.228"
            stroke="#FCFCFC"
          />
        </svg>

        <div className="left">
          <h1 style={{"marginBottom": "20px"}}>
            Why is Bunov<sup>&reg;</sup>
            <br /> (buprenorphine)
            one of the most affordable buprenorphine patches in the UK?<sup>1</sup>
          </h1>

          <ul>
            <li>
              <div className="icon">
                <img src={HandSVG} alt="" />
              </div>
              <span>
                NHS price 69% below Drug Tariff price<sup>1</sup>
              </span>
            </li>


            <li>
              <div className="icon">
                <img src={PatchSVG} alt="" />
              </div>
              <span>
                Switching prescribing to Bunov<sup>&reg;</sup> from other 7-day
                buprenorphine patches may deliver immediate cost improvements
                <sup>1</sup>
              </span>
            </li>
          </ul>
          <p>
            Bunov<sup>&reg;</sup> is bioequivalent to Butrans<sup>&reg;</sup>{" "}
            and is one of the most affordable buprenorphine patches in the UK
            <sup>1,2</sup>
          </p>
        </div>
        <div className="right">
          <div className="image-wrapper">
            <Image
              webp={Hero1WEBP}
              src={Hero1JPG}
              alt="A middle-aged man is using a tablet computer to look up information about his medication."
            />
            <Image
              webp={Hero2WEBP}
              src={Hero2JPG}
              alt="A woman is browsing a website on her tablet to find out more information about her medication."
            />
            <Image
              webp={Hero3WEBP}
              src={Hero3JPG}
              alt="An elderly gentleman is sitting on a bench and enjoying the fresh air."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
