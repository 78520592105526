import React, { useEffect } from "react";
import PInformation from "../../../img/pi-v2.png";

export default function PI() {
  useEffect(() => {
    document.title = "Bunov® - Prescribing Information";
  }, []);
  return (
    <main>
      <section className="section section--grey section--full-width section--report">
        <div className="inner-container">
          <img src={PInformation} alt="Prescribing Information" />
        </div>
      </section>
    </main>
  );
}
