import React from "react";
import { useSite } from "../../hooks";
import { NavLink } from "react-router-dom";

export default function CTA(props) {
  const { currentSite } = useSite();

  const Link = () => {
    if (!props.link) return;

    if (props.link.startsWith("http") || props.link.startsWith("mailto")) {
      return (
        <a
          href={props.link}
          target="_blank"
          className={
            currentSite === "hcp"
              ? "btn btn--primary btn--icon cta-btn"
              : "btn btn--secondary btn--icon cta-btn"
          }
        >
          {props.linkCopy} {props.buttonIcon && <img src={props.buttonIcon} />}
        </a>
      );
    } else {
      return (
        <NavLink
          to={props.link}
          data-path={props.link}
          className={
            currentSite === "hcp"
              ? "btn btn--primary btn--icon cta-btn"
              : "btn btn--secondary btn--icon cta-btn"
          }
        >
          {props.linkCopy} {props.buttonIcon && <img src={props.buttonIcon} />}
        </NavLink>
      );
    }
  };

  return (
    <div
      id={props.id}
      className={
        props.styles
          ? `call-to-action call-to-action--${props.styles} ${props.className}`
          : `call-to-action ${props.className}`
      }
    >
      <svg
        width="513"
        height="322"
        viewBox="0 0 513 322"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="circles"
      >
        <path
          opacity="0.2"
          d="M81 0.699585C33.396 26.1079 1 76.2707 1 134C1 217.395 68.605 285 152 285C235.395 285 303 217.395 303 134C303 76.2707 270.604 26.1079 223 0.699585"
          stroke="#FCFCFC"
        />
        <circle opacity="0.2" cx="285.5" cy="241.5" r="80" stroke="#FCFCFC" />
        <path
          opacity="0.2"
          d="M134 -2.34436C184.395 42.062 250.552 69 323 69C395.448 69 461.605 42.062 512 -2.34436"
          stroke="#FCFCFC"
        />
      </svg>

      <div className="inner-container">
        {props.icon && <img className="cta-icon" src={props.icon} alt=" " />}

        {props.title && <div className="title">{props.title}</div>}

        <div
          className="cta-heading"
          dangerouslySetInnerHTML={{ __html: props.heading }}
        ></div>
        <div
          className="copy"
          dangerouslySetInnerHTML={{ __html: props.copy }}
        ></div>
        <Link />
      </div>
    </div>
  );
}
