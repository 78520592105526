import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HCPPopup, ExternalPopup, CookiePopup } from "./components";
import { useSite, useScript } from "./hooks";
import HCP from "./HCP";
import Patient from "./Patient";
import PAR from "./pages/par";
import Redirect from "./pages/redirect";
import "./css/scss/main.scss";
import TagManager from "react-gtm-module";

export const AppContext = createContext();

function App() {
  const [showExternalLinkPopup, setShowExternalLinkPopup] = useState(false);
  const [externalLinkURL, setExternalLinkURL] = useState();
  const { currentSite } = useSite();
  const location = useLocation();
  const [shownCookiePopup, setShownCookiePopup] = useState(false);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MHGCCJN",
      dataLayer: {
        site: currentSite,
      },
    };

    TagManager.initialize(tagManagerArgs);
  }, [currentSite]);

  useEffect(() => {
    const script = document.createElement("script");

    if (location.pathname !== "/hcp/pi" && !shownCookiePopup) {
      script.src = "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js";
      script.type = "text/javascript";
      script.setAttribute("data-domain-script", "ef64da7a-9821-4462-97bd-e371e6ec56e6");

      document.body.appendChild(script);
      setShownCookiePopup(true);
    }
  }, [location.pathname]);

  const loadCurrentSite = () => {
    if (currentSite === "hcp") return <HCP />;
    else if (currentSite === "patient") return <Patient />;
    else if (currentSite === "par") return <PAR />;
    else if (currentSite === "redirect") return <Redirect />;
    else return <HCPPopup />;
  };

  return (
    <AppContext.Provider
      value={{
        location,
        externalLinkURL,
        setExternalLinkURL: setExternalLinkURL,
        setShowExternalLinkPopup: setShowExternalLinkPopup,
      }}
    >
      {loadCurrentSite()}
      {showExternalLinkPopup && <ExternalPopup />}
    </AppContext.Provider>
  );
}

export default App;
