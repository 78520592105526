import { useState, useEffect, useLayoutEffect, useContext } from "react";
import { AppContext } from "../App";

export default function useDOP() {
  const { location } = useContext(AppContext);
  const [popupVisible, setPopupVisible] = useState(false);
  const [dop, setDop] = useState("September 2022");

  useLayoutEffect(() => {
    const hcpPopup = document.querySelector(".hcp-popup-header");

    if (hcpPopup) {
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (popupVisible) {
      setDop("September 2023");
    } else {
      switch (location.pathname) {
        case "/hcp/":
          setDop("May 2024");
          break;
        case "/hcp":
          setDop("May 2024");
          break;
        case "/patient/":
        case "/patient":
        case "/patient/how-to-apply-bunov":
        case "/patient/side-effects":
        case "/par":
        case "/par/":
          setDop("October 2023");
          break;
        case "/hcp/initiating-bunov":
        case "/hcp/pi":
          setDop("September 2023");
          break;

        default:
          setDop("September 2023");
          break;
      }
    }
  }, [popupVisible, location.pathname]);

  return dop;
}
